<template>
  <div class="full-recipe">
    <div class="container">
      <div class="full-recipe__nav">
        <locale-router-link
          to=""
          class="crumbs-link main-page-link"
        >
          {{ $t("home") }}
        </locale-router-link>
        <locale-router-link
          to="/recipes"
          class="crumbs-link"
        >
          {{ $t("recipes.title") }}
        </locale-router-link>
        <a class="crumbs-link recipes-link">{{ recipeTitle }}</a>
      </div>

      <div class="full-recipe__main">
        <h1 class="full-recipe__title">{{ recipeTitle }}</h1>
        <div
          class="full-recipe__actions"
          v-if="$isMobile()"
        >
          <app-share-actions />

          <div class="full-recipe__print">
            <img
              src="@/assets/img/products/print.svg"
              @click="hanldePrintPage()"
              alt=""
            />
          </div>
        </div>
        <div class="full-recipe__divider"></div>

        <div
          v-if="recipeData"
          class="full-recipe__main-inner"
        >
          <div class="full-recipe__content">
            <div class="full-recipe__image">
              <img
                :src="getImageUrl(recipeData.attributes.image)"
                alt=""
              />
            </div>
            <p
              v-if="recipeData.attributes.content"
              class="full-recipe__description full-recipe__text"
            >
              {{ recipeData.attributes.content }}
            </p>
            <div
              v-if="!$isMobile()"
              class="full-recipe__steps"
            >
              <h3 class="full-recipe__title-small">Приготовление:</h3>
              <div
                :key="item.text"
                v-for="(item, index) in recipeData.attributes.steps"
                class="full-recipe__steps-item"
              >
                <h4>
                  <b>Шаг {{ index + 1 }}</b>
                </h4>
                <p class="full-recipe__text">
                  {{ item.text }}
                </p>
                <img
                  v-if="item.img.data"
                  :src="getImageUrl(item.img)"
                />
              </div>
            </div>
          </div>

          <div class="full-recipe__aside">
            <div
              class="full-recipe__actions"
              v-if="!$isMobile()"
            >
              <app-share-actions />
              <div class="full-recipe__print">
                <img
                  src="@/assets/img/products/print.svg"
                  @click="hanldePrintPage()"
                  alt=""
                />
              </div>
            </div>

            <div class="full-recipe__info">
              <div class="full-recipe__info-top">
                <p class="full-recipe__date">
                  {{ getDateFormat(recipeData.attributes.publishedAt) }}
                </p>
                <div class="full-recipe__views-count recipes__views-count">
                  {{ recipeData.attributes.hits }}
                </div>
              </div>
              <div class="full-recipe__info-list">
                <p class="full-recipe__info-list-item">
                  <b>Время приготовления: </b><span>{{ recipeData.attributes.ETA }}</span>
                </p>
                <p class="full-recipe__info-list-item">
                  <b>Сложность: </b
                  ><span>{{ getDifficultyLabel(recipeData.attributes.difficulty) }}</span>
                </p>
              </div>
            </div>

            <div class="full-recipe__divider"></div>

            <div
              class="full-recipe__value"
              v-if="recipeData.attributes.nutrition_facts"
            >
              <h3 class="full-recipe__title-small">Пищевая ценность на 100 г</h3>
              <div class="full-recipe__value-inner">
                <div class="full-recipe__value-item">
                  <p>{{ recipeData.attributes.nutrition_facts.cals }} kcal</p>
                  <span>каллории</span>
                </div>
                <div class="full-recipe__value-item">
                  <p>{{ recipeData.attributes.nutrition_facts.protein }} г</p>
                  <span>белки</span>
                </div>
                <div class="full-recipe__value-item">
                  <p>{{ recipeData.attributes.nutrition_facts.fats }} г</p>
                  <span>жиры</span>
                </div>
                <div class="full-recipe__value-item">
                  <p>{{ recipeData.attributes.nutrition_facts.carbonates }} г</p>
                  <span>углеводы</span>
                </div>
              </div>
            </div>

            <div class="full-recipe__block">
              <h3
                v-if="$isMobile()"
                class="full-recipe__title-small"
              >
                Ингредиенты
              </h3>

              <div
                class="full-recipe__ingredients"
                v-if="recipeData.attributes.recipe_details"
              >
                <div
                  v-if="!$isMobile()"
                  class="full-recipe__ingredients-top"
                >
                  <h3 class="full-recipe__title-small">Ингредиенты</h3>
                  <div class="full-recipe__ingredients-portion">
                    <span>порции</span>
                    <div class="full-recipe__ingredients-portion-inner">
                      <button
                        :disabled="portionCount <= 1"
                        @click="portionCount -= 1"
                      >
                        -
                      </button>
                      <span>{{ portionCount }}</span>
                      <button @click="portionCount += 1">+</button>
                    </div>
                  </div>
                </div>
                <div class="full-recipe__ingredients-list">
                  <div
                    class="full-recipe__ingredients-item"
                    :key="item.name"
                    v-for="item in recipeData.attributes.recipe_details"
                  >
                    <p>{{ item.name }}</p>
                    <p class="full-recipe__ingredients-item-measure">
                      <span>{{ calculateIngredientValue(item.quantity) }}</span
                      ><span v-if="item.type">{{ $t(`recipes.ingredients['${item.type}']`) }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="$isMobile()"
          style="margin-top: 30px"
          class="full-recipe__steps"
        >
          <h3 class="full-recipe__title-small">Приготовление:</h3>
          <div
            :key="item.text"
            v-for="(item, index) in recipeData.attributes.steps"
            class="full-recipe__steps-item"
          >
            <h4>
              <b>Шаг {{ index + 1 }}</b>
            </h4>
            <p class="full-recipe__text">
              {{ item.text }}
            </p>
            <img
              v-if="item.img.data"
              :src="getImageUrl(item.img)"
            />
          </div>
        </div>
      </div>

      <div
        v-if="subcategories.length > 0"
        class="full-recipe__useful full-recipe__section"
      >
        <recipes-slider :products="products"/>
      </div>

      <div
        class="full-recipe__another full-recipe__section"
        v-if="anotherRecipeItems.length"
      >
        <h2 class="full-recipe__another-title full-recipe__section-title">
          Другие вкусные рецепты
        </h2>
        <div class="full-recipe__another-items">
          <app-recipes-item
            v-for="(item, index) in getAnotherRecipes"
            :key="item.id"
            :class="{ big: checkItemSize(index) }"
            :item="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import { getRecipeById, getAllRecipes } from "@/api/recipes"

import AppRecipesItem from "@/components/recipes/AppRecipesItem.vue"
import AppShareActions from "@/components/common/AppShareActions.vue"
import RecipesSlider from "../../components/recipes/RecipesSlider.vue"
import { getAllProducts, getProductsBySubcategory } from "@/api/products";
import {mapState} from "vuex";

export default {
  name: "FullRecipe",

  components: {
    AppRecipesItem,
    AppShareActions,
    RecipesSlider,
  },

  data() {
    return {
      recipeData: {},
      recipeTitle: "",
      portionCount: 1,
      difficultyLabels: {
        1: this.$t("recipes.difficulty.veryEasy"),
        2: this.$t("recipes.difficulty.easy"),
        3: this.$t("recipes.difficulty.medium"),
        4: this.$t("recipes.difficulty.difficult"),
        5: this.$t("recipes.difficulty.veryDifficult"),
      },
      anotherRecipeItems: [],
      subcategories: [],
      products: [],
    }
  },

  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    getAnotherRecipes() {
      return this.anotherRecipeItems.slice(0, 5)
    },
  },

  watch: {
    "$attrs.id": {
      handler() {
        this.subcategories = []
        this.loadRecipeData()
      },
    },
    selectedCity: {
      deep: true,
      handler() {
        getAllProducts(1, 10, this.subcategories, this.selectedCity.id).then((response) => {
          this.products = response.data
          if (this.products.length > 4) {
            this.swiperOption.loop = true
          } else {
            this.swiperOption.loop = false
          }
        })
      },
    },
  },

  mounted() {
    this.loadRecipeData()
  },

  methods: {
    async loadRecipeData() {
      const recipeResponse = await getRecipeById(this.$attrs?.id)
      this.recipeData = recipeResponse.data
      this.recipeTitle = this.recipeData.attributes.title
      if (this.$i18n.locale === "kz") {
        const recipeResponse = await getRecipeById(
          this.recipeData.attributes.localizations?.data[0]?.id,
        )
        this.recipeData = recipeResponse.data
      }
      recipeResponse.data.attributes.recipe_details.forEach((e) => {
        if (e.subcategory?.data?.id) {
          this.subcategories.push(e.subcategory.data.id)
        }
      })
      const anotherRecipesResponse = await getAllRecipes(1, 100, this.recipeData.id)
      this.anotherRecipeItems = anotherRecipesResponse.data
      this.loadProductsBySubcategory()
    },
    async loadProductsBySubcategory() {
      if (this.selectedCity) {
        await getProductsBySubcategory(1, 10, this.subcategories, this.selectedCity?.id).then(
            (response) => {
              this.products = response.data
            },
        )
      }
    },
    getImageUrl(image) {
      if (image?.data) {
        return this.$helpers.getAbsolutePath(image?.data?.attributes?.url)
      }
      return require("@/" + "assets/img/icons/placeholder.svg")
    },
    checkItemSize(e) {
      const index = e + 1
      return index % 5 === 1 || index % 5 === 2
    },
    calculateIngredientValue(value) {
      if (value === null) {
        return "-"
      }
      return value * this.portionCount
    },
    hanldePrintPage() {
      window.print()
    },
    getDateFormat(date) {
      return moment(date).locale(this.$i18n.locale).format("LL")
    },
    getDifficultyLabel(value) {
      return this.difficultyLabels[value]
    },
    getIngredientLabel(value) {
      return this.difficultyLabels[value]
    },
  },
}
</script>

<style scoped>
.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
}
</style>
